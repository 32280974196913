<template>
  <div class="container-xxl">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg">
            <input type="text" class="form-control" id="formAll" v-model="formAll" v-on:keyup.enter="enterEvent" v-on:blur="enterEvent"
                   placeholder="Keyword">
          </div>
          <div class="col-lg">
            <input type="text" class="form-control" id="formTitle" v-model="formTitle" v-on:keyup.enter="enterEvent" v-on:blur="enterEvent"
                   placeholder="Title">
          </div>
          <div class="col-lg">
            <input type="text" class="form-control" id="formActor" v-model="formActor" v-on:keyup.enter="enterEvent" v-on:blur="enterEvent"
                   placeholder="Cast">
          </div>
          <div class="col-lg">
            <input type="text" class="form-control" id="formPlot" v-model="formPlot" v-on:keyup.enter="enterEvent" v-on:blur="enterEvent"
                   placeholder="Plot">
          </div>
        </div>

        <div class="row m-3">
          <div class="col-1"></div>
          <div class="col-10 form-check  form-check-inline">
            <div v-for="genre in genres" :key="genre" class="form-check  form-check-inline">
              <label class="form-check-label" for={{genre}}>{{ genre }}</label>
              <input class="form-check-input" v-model="formGenres" type="checkbox" :value="genre" :name="genre" :id="genre"
                     @change="mainSearch()">
            </div>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row m-3">
          <div class="col-sm">
            <label for="formImdbScoreMin" class="form-label">IMDB score at least: {{ formImdbScoreMin / 10 }}</label>
            <input type="range" class="form-range" min="0" max="100" id="formImdbScoreMin"
                   v-model="formImdbScoreMin" v-on:mouseup="formImdbScoreMouseup">
          </div>
          <div class="col-sm">
            <label for="formImdbScoreMax" class="form-label">IMDB score at most: {{ formImdbScoreMax / 10 }}</label>
            <input type="range" class="form-range" min="0" max="100" id="formImdbScoreMax"
                   v-model="formImdbScoreMax" v-on:mouseup="formImdbScoreMouseup">
          </div>

          <div class="col-sm">
            <label for="formYearMin" class="form-label">Year from: {{ formYearMin }}</label>
            <input type="range" class="form-range" min="1950" max="2021" id="formYearMin"
                   v-model="formYearMin" v-on:mouseup="formYearMouseup">
          </div>
          <div class="col-sm">
            <label for="formYearMax" class="form-label">Year to: {{ formYearMax }}</label>
            <input type="range" class="form-range" min="1950" max="2021" id="formYearMax"
                   v-model="formYearMax" v-on:mouseup="formYearMouseup">
          </div>
        </div>

        <div class="row form-switch">
          <div class="col form-check form-check-inline" v-for="(item, idx) in orderByFields" :key="idx">
            <input class="form-check-input" type="checkbox" id="sortByYear" false-value="" :true-value="item"
                   v-model:="orderByFields[idx]"/>
            <label class="form-check-label" for="sortByYear">{{ item }}</label>
          </div>
        </div>
        <SearchClear @clear-search="clearSearch"/>
      </div>
    </div>
    <FilmCard :films="films"/>
  </div>
</template>

<script>
import FilmCard from './components/FilmCard'
import SearchClear from "@/components/SearchClear.vue";
import FilmJSON from './../films.json'
import {getFilms} from "@/service/film.api.ts";

/**
 * ordering
 * movie / tv series
 * date added
 * refactor service: https://dev.to/blindkai/managing-api-layers-in-vue-js-with-typescript-hno
 */

export default {
  name: 'App',
  components: {
    FilmCard,
    SearchClear
  },
  data() {
    return this.initdata()
  },
  watch: {
    orderByFields: {
      handler(val, oldVal) {
        console.log('order changed: ' + oldVal + ' -> ' + val)
      },
      deep: true
    },
    orderRating(newValue) {
      this.orderRating = newValue
      this.mainSearch()
    },
    orderYear(newValue) {
      this.orderYear = newValue
      this.mainSearch()
    }
  },
  methods: {
    initdata() {
      const initialYearTo = new Date().getFullYear()
      const initialYearFrom = initialYearTo - 24
      const initialImdbScoreMin = 82
      const initialImdbScoreMax = 100

      return {
        films: [],
        genres: [],
        formGenres: [],
        formAll: '',
        formTitle: '',
        formActor: '',
        formPlot: '',
        formImdbScoreMin: initialImdbScoreMin,
        formImdbScoreMax: initialImdbScoreMax,
        formYearMin: initialYearFrom,
        formYearMax: initialYearTo,
        orderByFields: ['addedDatetime', 'rating', 'year', 'title', 'length']
      }
    },
    loadFilms() {
      this.films = FilmJSON.filter((film) => {
        return !Number.isNaN(parseFloat(film['rating']))
      });

      console.log('loaded films: ' + this.films.length + 'order by: ' + this.orderRating, this.orderYear)

      if (this.films.length > 0) {
        return;
      }

      this.films = getFilms();
    },
    mainSearch() {
      this.logSearchParams();
      this.fetchData()

      const formScoreMin = parseFloat(this.formImdbScoreMin / 10)
      const formScoreMax = parseFloat(this.formImdbScoreMax / 10)

      // filter:
      // false - remove from array
      // true - stays in array
      this.films = this.films.filter(film => {
        // FILTER BY RATING
        const filmRating = parseFloat(film['rating'])

        if (formScoreMin > filmRating || formScoreMax < filmRating) {
          //console.log(formScoreMin + ' ## ' + filmRating)
          return false
          //result1 = true
        }

        // FILTER BY YEAR
        const filmYear = film['year']
        if (filmYear < this.formYearMin || filmYear > this.formYearMax) {
          return false
        }

        // FILTER BY TITLE
        const filmTitle = film['title']
        if (this.formTitleFormatted != "" && !filmTitle?.toLowerCase().includes(this.formTitleFormatted)) {
          return false
        }

        // FILTER BY PLOT
        const filmPlot = film['plot']
        if (this.formPlotFormatted != "" && !filmPlot?.toLowerCase().includes(this.formPlotFormatted)) {
          return false
        }

        // FILTER BY ALL TEXT
        if (this.formAllFormatted != ""
            && (!filmPlot?.toLowerCase().includes(this.formAllFormatted)
                && !filmTitle?.toLowerCase().includes(this.formAllFormatted))) {
          return false
        }
        return true
      })

      this.films.sort((a, b) => a['title'].localeCompare(b['title']))

      console.log('mainSearch end, films matched: ' + this.films.length)
    },
    orderBy(value) {
      console.log('order by: ' + value)
      if (!this.orderByFields.includes(value)) {
        this.orderByFields.p
      }
    },
    fetchData() {
      console.log('resetFormIfEmpty')
      this.loadFilms()
    },
    formImdbScoreMouseup() {
      this.mainSearch()
    },
    formYearMouseup() {
      console.log('#formYearMouseup')
      this.mainSearch()
    },
    resultsOrder(orderBy) {
      console.log('order by: ' + orderBy)
    },
    enterEvent() {
      this.mainSearch()
    },
    clearSearch() {
      console.log('clearSearch started')
      this.data = this.initdata()
      this.loadFilms()
    },
    logSearchParams() {
      console.log('Search, filter settings: ' +
          'title: ' + this.formTitle + ', ' +
          'title: ' + this.formTitleFormatted + ', ' +
          'actor: ' + this.formActor + ', ' +
          'plot: ' + this.formPlotFormatted + ', ' +
          'IMDB score min: ' + this.formImdbScoreMin + ', ' +
          'IMDB score max: ' + this.formImdbScoreMax + ', ' +
          'year min: ' + this.formYearMin + ', ' +
          'year max:' + this.formYearMax)
    }
  },
  computed: {
    formTitleFormatted: function () {
      return this.formTitle.toLowerCase()
    },
    formAllFormatted: function () {
      return this.formAll.toLowerCase()
    },
    formPlotFormatted: function () {
      return this.formPlot.toLowerCase()
    }
  },
  created() {
    this.mainSearch()
  }
}
</script>

<style>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
