<template>
  <div class="row mt-3">
    <div class="col-5"></div>
    <div class="col-2">
      <button type="button" class="btn btn-primary" @click="$emit('clearSearch')">Puhasta</button>
    </div>
    <div class="col-5"></div>
  </div>
</template>

<script>
export default {
  name: 'SearchClear',
}
</script>
