import axios from "axios";

const axiosClient = axios.create({
  baseURL: 'https://film-be.vinyl.rehab/api/v1',
  auth: {
    username: process.env.VUE_APP_API_USERNAME,
    password: process.env.VUE_APP_API_PASSWORD
  }
})


export async function getFilms() {
  await axiosClient.get('/films')
  .then(response => {
    console.log(response.data)

    response.data.forEach(item => {
      if (item.image == null || item.image.length == 0) {
        item.image = require('..//assets/blank.jpg')
      }
      this.films.push(item)
    })
    this.films = response.data
  })
  .catch(error => {
    this.errorMessage = error.message;
    console.error("There was an error!", error);
  });

}
