<template>
  <div class="row m-2 justify-content-center">
    <div v-for="(item, index) in films" v-bind:key="index" class="card m-1"
         style="max-width: 18rem;">
      <img :src="item.image" class="card-img-top mt-2" alt="{{ item.title }}">
      <div class="card-body text-dark p-1">
        <h5 class="card-title text-center">
          <a :href="imdbURL(item.imdbid)" target="_blank">{{ item.title }} ({{ item.year }})</a>
        </h5>
        <small>
          <p class="text-center font-italic">
            <span v-for="(director, index) in item.directors" v-bind:key="index">{{ director.name }}, </span>
          </p>

          <div class="accordion" id="`accordion-${index}`">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button p-1" type="button" data-bs-toggle="collapse" :data-bs-target="`#${index}`" aria-expanded="true"
                        aria-controls="collapseOne">
                  <p class="card-text">
                    <span v-for="(actor, index) in item.actors" v-bind:key="index"> {{ actor.name }}, </span>
                  </p>
                </button>
              </h2>
              <div :id="index" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  {{ item.plot }}
                </div>
              </div>
            </div>
          </div>
        </small>
      </div>
      <div class="card-footer bg-transparent border-success">{{ item.rating }}</div>
      <div class="card-footer bg-transparent border-success">{{ formatRuntime(item.runtime) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilmCard',
  props: {
    films: Array
  },
  methods: {
    formatRuntime: function (value) {
      const hours = value / 60
      if (hours == 0) {
        return value + "min"
      }
      return Math.round(hours) + 'h ' + value % 60 + "min"
    },
    imdbURL: function (value) {
      return 'https://imdb.com/title/' + value
    }
  }
}
</script>
